<template>
  <div>

    <!-- Alert: -->
    <b-alert
      variant="danger"
    >
      <h4 class="alert-heading" />
      <div class="alert-body" />
    </b-alert>
    <div>
      <validation-observer
        ref="importForm"
        #default="{ invalid }"
      >
        <b-form
          @submit.prevent="importCases"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                md="4"
                lg="4"
              >
                <b-form-group
                  label-for="file"
                >
                  <template v-slot:label>
                    {{ $t('case.upload.field.file') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('case.upload.field.file')"
                    rules="required"
                  >
                    <b-form-file
                      id="file"
                      v-model="fileData.file"
                      accept=".xls, .xlsx"
                      :placeholder="$t('case.upload.label.selectFile')"
                      drop-placeholder="Suéltelo aquí..."
                      browse-text="Buscar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="4"
              >
                <b-form-group
                  label-for="date"
                >
                  <template v-slot:label>
                    {{ $t('case.upload.field.date') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('case.upload.field.date')"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="date"
                      v-model="fileData.date"
                      :placeholder="$t('case.upload.label.selectDate')"
                      :local="$i18n.locale"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
                lg="4"
              >
                <b-form-group
                  label-for="firt_name"
                >
                  <template v-slot:label>
                    {{ $t('case.upload.field.arl') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('case.upload.field.arl')"
                    rules="required"
                  >
                    <v-select
                      id="arl"
                      v-model="fileData.arlId"
                      :options="companyList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="3"
                md="3"
                lg="3"
              >
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid"
                >
                  {{ $t('case.upload.submit') }}
                </b-button>
              </b-col>
              <b-col
                cols="9"
                md="9"
                lg="9"
              >
                <b-card-text class="my-1 text-right">
                  {{ $t('case.upload.label.selected') }}: <strong>{{ fileData.file ? fileData.file.name : 'Ninguno' }}</strong>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </div>
    <div v-if="details.length != 0">
      <b-card>
        <b-row
          v-if="uploadSuccess"
          class="flex-row flex-nowrap"
        >
          <b-col
            cols="5"
            class="text-center text-primary text-uppercase font-weight-bold"
          >
            {{ $t("case.upload.title.patient") }}
          </b-col>
          <b-col
            cols="4"
            class="text-center text-primary text-uppercase font-weight-bold"
          >
            {{ $t("case.upload.title.company") }}
          </b-col>
          <b-col
            cols="3"
            class="text-center text-primary text-uppercase font-weight-bold"
          >
            {{ $t("case.upload.title.case") }}
          </b-col>
        </b-row>
        <b-row class="flex-row flex-nowrap">
          <b-col
            cols="12"
          >
            <vue-double-scrollbar>
              <b-table
                :items="details"
                :fields="detailsColumns"
                class="text-center"
                striped
                thead-class="table-primary text-primary"
              >
                <template #cell(type)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.type)}`"
                  >
                    {{ $t(`case.upload.status.${data.item.type}`) }}
                  </b-badge>
                </template>

                <template #cell(patientStatus)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.patientStatus)}`"
                  >
                    {{ $t(`case.upload.status.${data.item.patientStatus}`) }}
                    {{ data.item.type }}
                  </b-badge>
                </template>

                <template #cell(companyStatus)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.companyStatus)}`"
                  >
                    {{ $t(`case.upload.status.${data.item.companyStatus}`) }}
                  </b-badge>
                </template>

                <template #cell(caseStatus)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveStatusVariantError(data.item.caseStatus)}`"
                  >
                    {{ $t(`case.upload.status.${data.item.caseStatus}`) }}
                  </b-badge>
                </template>

              </b-table>
            </vue-double-scrollbar>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="12"
          >
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="export2excel"
            >
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BFormFile, BCard, BCardText, BRow, BCol, BAlert, BTable, BFormDatepicker, BButton, BForm, BFormGroup, BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import * as XLSX from 'xlsx'
import vSelect from 'vue-select'
import elementStoreModule from '../storeModule'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BFormFile,
    BCard,
    BCardText,
    BFormDatepicker,
    BButton,
    BFormGroup,
    BForm,
    BTable,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      RESOURCES_ELEMENT_NAME: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      fileData: {
        arlId: null,
        date: new Date(),
        file: null,
      },
      file: null,
      uploadSuccess: false,
      companyList: [],
      details: [],
      detailsColumnsError: [
        { key: 'type', label: this.$t('case.upload.label.type'), sortable: true },
        { key: 'message', label: this.$t('case.upload.label.message'), sortable: true },
      ],
      detailsColumnsSuccess: [
        { key: 'patientId', label: this.$t('case.upload.label.patientId'), sortable: true },
        { key: 'patientName', label: this.$t('case.upload.label.patientName'), sortable: true },
        { key: 'arl', label: this.$t('case.upload.label.arl') },
        { key: 'patientStatus', label: this.$t('case.upload.label.patientStatus'), sortable: true },
        { key: 'companyId', label: this.$t('case.upload.label.companyId'), sortable: true },
        { key: 'companyName', label: this.$t('case.upload.label.companyName'), sortable: true },
        { key: 'companyStatus', label: this.$t('case.upload.label.companyStatus'), sortable: true },
        { key: 'caseNumber', label: this.$t('case.upload.label.caseNumber'), sortable: true },
        { key: 'caseStatus', label: this.$t('case.upload.label.caseStatus'), sortable: true },
        { key: 'caseErrorMessage', label: this.$t('case.upload.label.caseErrorMessage'), sortable: true },
      ],
    }
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'case'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-case-up-${this.RESOURCES_ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, elementStoreModule)
    this.details = []
    await this.fetchCompanies()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    resolveStatusVariant(status) {
      if (status === 'error') return 'danger'
      return 'warning'
    },
    resolveStatusVariantError(status) {
      if (status === 'exist' || status === 'error') return 'danger'
      return 'success'
    },
    fetchCompanies() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchCompanies`)
        .then(response => {
          this.companyList = response.data.items
          this.companyList.unshift({ id: null, name: this.$t('list.defaultOption') })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.companyList = undefined
          }
        })
    },
    importCases() {
      const _self = this
      const action = 'upload'
      this.details = []
      this.uploadSuccess = false
      this.$refs.importForm.validate().then(success => {
        if (success) {
          store
            .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/importCases`, this.fileData)
            .then(response => {
              _self.detailsColumns = _self.detailsColumnsSuccess
              _self.uploadSuccess = true
              _self.details = response.data.details

              console.info('details:', response.data.details)

              let company_name = ''
              _self.companyList.forEach(company => {
                if (company.id == this.fileData.arlId) {
                  company_name = company.name
                }
              })

              _self.details.forEach(row => {
                row.arl = company_name
              })

              this.fileData.arlId = null
              this.fileData.date = new Date()
              this.fileData.file = null
              this.$refs.importForm.reset()

              _self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
                  icon: 'SaveIcon',
                  variant: 'success',
                  text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.success`),
                },
              })
            })
            .catch(error => {
              console.info(error)
              if (error.response.data) {
                _self.detailsColumns = _self.detailsColumnsError
                _self.details = error.response.data.details
              }
              _self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.title`),
                  icon: 'SaveIcon',
                  variant: 'danger',
                  text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.${action}.failure`),
                },
              })
            })
        }
      })
    },
    filterJson(jsonData) {
      const filterList = this.detailsColumnsSuccess.map(a => a.key)
      // filterList.pop();
      return jsonData.map(v => filterList.map(j => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.detailsColumnsSuccess.length; i++) {
        res[this.detailsColumnsSuccess[i].key] = this.detailsColumnsSuccess[i].label
      }
      return res
    },
    format(jsonData) {
      for (let i = 0; i < jsonData.length; i++) {
        jsonData[i].patientStatus = jsonData[i].patientStatus ? this.$t(`case.upload.status.${jsonData[i].patientStatus}`) : ''
        jsonData[i].companyStatus = jsonData[i].companyStatus ? this.$t(`case.upload.status.${jsonData[i].companyStatus}`) : ''
        jsonData[i].caseStatus = jsonData[i].caseStatus ? this.$t(`case.upload.status.${jsonData[i].caseStatus}`) : ''
      }
    },
    async export2excel() {
      const filename = 'resultados'
      let list = JSON.parse(JSON.stringify(this.details))
      this.format(list)
      list.unshift(this.getHeader())
      list = this.filterJson(list)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
  },
}
</script>

<style>
  .scrollbar-container {
    overflow-y: auto;
    margin: 0 0;
  }

  .scrollbar-content {
    margin: 0 0;
  }

  .scrollbar-flipped, .scrollbar-flipped .scrollbar-content {
    transform: rotateX(180deg);
  }
</style>
