import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx, params) {
      console.info('companyType', 'ARL')
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', {
            params: {
              perPage: 50, page: 1, companyType: 'ARL', only_active: true,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_other_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/case/${params.patient_id}/${params.case_id}`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importCases(ctx, fileData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', fileData.file)
        axios
          .post(`/api/v1/cases/upload/${fileData.arlId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            params: {
              date: fileData.date,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
